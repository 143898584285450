<template>
  <b-container>
    <h1>Create Account</h1>
    
  </b-container>
</template>

<script>
export default {
  name: 'CreateAccount'
};
</script>

<style scoped>

</style>
